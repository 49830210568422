import React,{ useState, useEffect,useRef } from 'react';
import { preUploadFile, uploadFile, serviceFetchFailedLogs, getFileLink, updateFileUploadStatus } from '../../../services/rcm/Service';
import { CustomLoader,decruptCipherJsonReact,encryptCipherJsonReact, ModalBox, decruptCipherJson, ModalBoxButton } from '../../utils/CommonLibrary';
import UploadMemberDateTable from './UploadMemberDateTable';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RCMFileInsight from '../ContentRCMTeamDashboard/RCMFileInsight';
import RCMCareProgramTable from '../ContentRCMTeamDashboard/RCMCareProgramTable';

function ContentUploadMemberNew() {

  let storedUploadDetails = JSON.parse(localStorage.getItem('uploadDetails')) || '';
 
  if(storedUploadDetails != ''){
    storedUploadDetails = decruptCipherJsonReact(storedUploadDetails);
    storedUploadDetails = JSON.parse(storedUploadDetails);
  }
  
  const [file,setFile] = useState('');
  const [loading,setLoading] = useState(false);
  const [failDataExists,setFailDataExists] = useState('');
  const [failDataNew,setFailDataNew] = useState('');
  const [fileUrl,setFileUrl] = useState('');
  const [textMsg,setTextMsg] = useState('');
  const [tempUpload,setTempUpload] = useState('');
  const [failFileUrl,setFailFileUrl] = useState('');
  const [failFileUrlExists,setFailFileUrlExists] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  
  const [showModal, setShowModal] = useState(false);
  const handleClose = () =>{  setShowModal(false);}
  const handleCloseButton = () =>{  setShowModalButton(false);}
  const [modalTitle, setModalTitle] = useState('');
  const [modalClass, setModalClass] = useState('md');
  const [modalBody, setModalBody] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [uniqueUploadId, setUniqueUploadId] = useState(0);

  const [showModalButton, setShowModalButton] = useState(false);
  const [showPopupButton, setShowPopupButton] = useState(false);
  const [modalBodyNew, setModalBodyNew] = useState('');

  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);

  const [disableOne, setDisableOne] = useState(false);
  const [disableTwo, setDisableTwo] = useState(true);
  const [disableThr, setDisableThr] = useState(true);
  const [disableFour, setDisableFour] = useState(true);
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState('');
  const [showClose, setShowClose] = useState(true);
  const [programData, setProgramData] = useState([]);

  const [showLoaderNew, setShowLoaderNew] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [intervalId, setIntervalId] = useState(storedUploadDetails.intervalId || null);
  const [showList, setShowList] = useState(storedUploadDetails.showList || false);
  const [insightData, setInsightData ] = useState({'totalUpload':0,'totalSuccess':0, 'totalFail':0,'successPer':0,'failPer':0,'errorMsg':'','errorMsgExists':'','newTxtMsg':'' });
  const intervalRef = useRef(null);
  const buttonIntervalRef = useRef(null);



  useEffect(() => {
    
    fetchFileUrl();
    
    if (showList) {
      setSelectedTab(2);
      setStepOne(true);
      setStepTwo(true);
      setDisableThr(true);
      setDisableFour(true);
      setLoading(true);
      fetchFailData(intervalId);
      setShowLoaderNew(true);
      setShowButton(false);
      setButtonDisabled(false);
      intervalRef.current = setInterval(() => {
        fetchFailData(intervalId);
      }, 3000);
    }
    
    return () => {
      clearInterval(intervalRef.current);
    };
    
  }, []);

  const fetchFileUrl = () => {
    getFileLink()
      .then((res) => {
          setFileUrl(res.data.data.fileUrl);
          setProgramData(res.data.data.programData);
        }
      );
  }

  const handleChange = (e) => {
    
    const MAX_FILE_SIZE_MB = 1.9;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
    const file = e.target.files[0];
    const fileSizeBytes = file.size;

    if (fileSizeBytes > MAX_FILE_SIZE_BYTES) {
      setModalTitle('Alert');
      setModalBody('The file size exceeds the 2 MB limit. Please upload a smaller file.');
      setShowModal(true);
      document.getElementById(e.target.id).value = '';
      return;
    }
  
    setFile(file);
    setHideButton(true);
  };


  const uploadPreFile = async (e) => {
    if(file!=''){
        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        preUploadFile(formData).then((response) => {
            setFile('');
            document.getElementById("xlaxFile_upload").value = "";
            setLoading(false);
            if(response.data.code == 200){
              setButtonDisabled(true);
              const insightUploadId = decruptCipherJson(response.data.data);
              setTempUpload(insightUploadId.uploadId);
              setTextMsg(insightUploadId.txtMsg);
            }else{
              setModalTitle('Alert');
              setModalBody(response.data.data.message);
              setShowModal(true);
            }
        }
      );
    }else{
        setModalTitle('Alert');
        setModalBody('Please select file');
        setShowModal(true);
    }
  }


  const uploadFile_Html = (tempUploadValue) => {
    setLoading(true);
    uploadFile(tempUploadValue)
      .then((response) => {

        if(response.data.code == 200){
            buttonIntervalRef.current = setInterval(() => {
              fetchFailData(response.data.uploadId);
            }, 3000);
            setShowLoaderNew(true);
            setButtonDisabled(true);
            setShowList(true);
            let uploadDetails = { 'intervalId': response.data.uploadId, 'showList': true};
            const uploadDetailsNew = encryptCipherJsonReact(JSON.stringify(uploadDetails));
            localStorage.setItem('uploadDetails', JSON.stringify(uploadDetailsNew));
            setSelectedTab(2);
            setDisableTwo(true);
            setDisableThr(false);
            setDisableFour(true);
            setStepTwo(true);
          }else{
            setModalTitle('Alert');
            setModalBody(response.data.data.message);
            setShowModal(true);
            setShowButton(false);
            setButtonDisabled(false);
            setShowList(false);
          }
          setLoading(false);
        }
      );
  }

  const fetchFailData = (uidStore) => {
     serviceFetchFailedLogs(uidStore)  
       .then((response) => {

          let insightData = response.data.data;

          if(insightData.data.insight || insightData.data.uploadStatus != 'F'){
            setDisableThr(false);
            setDisableOne(false);
            setInsightData(prevState => ({ ...prevState, totalUpload: parseInt(insightData.data.insight.totalUpload),totalSuccess: parseInt(insightData.data.insight.totalSuccess),totalFail: parseInt(insightData.data.insight.totalFail), successPer: parseInt(insightData.data.insight.successPer), failPer: parseInt(insightData.data.insight.failPer), errorMsg: insightData.data.insight.errorMsg, errorMsgExists: insightData.data.insight.errorMsgExists, newTxtMsg: insightData.data.insight.newTxtMsg}));
            setFailDataNew(insightData.data.failedArray);
            setFailDataExists(insightData.data.alreadyExistArray);
          }

          if(insightData.data.uploadStatus == 'Y'){
            setSelectedTab(3);
            
            setDisableOne(false);
            setDisableTwo(true);
            setDisableThr(true);
            setStepThree(true);
            setStepFour(true);
            setDisableFour(false);
            clearInterval(intervalRef.current);
            clearInterval(buttonIntervalRef.current);
            intervalRef.current = null;
            buttonIntervalRef.current = null;
            setShowButton(false); 
            setShowLoaderNew(false);
            setFailFileUrl(insightData.data.fileUrl);
            setFailFileUrlExists(insightData.data.fileUrlExists);
            setButtonDisabled(false);
            setUniqueUploadId(insightData.data.uploadId);
            
            
            if(insightData.data.insight.showModal){
              setModalBodyNew(insightData.data.insight.fileStatus)
              setModalTitle('Message!');
              setShowModalButton(true);
              setShowPopupButton(true);
              setShowClose(false);
            }
          }else if(insightData.data.uploadStatus == 'N'){
            setShowButton(true);
            setButtonDisabled(true);
          }else{
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setLoading(false);
            setModalTitle('Alert');
            setModalBody('Some issue in file reading. please try again with correct file');
            setShowModal(true);
            setShowButton(false);
            setButtonDisabled(false);
            setShowList(false);
          }
          setLoading(false);
        }
      );
  }
  
  const startProcess = () => {
   
    setStepTwo(false);
    setStepThree(false);
    setStepFour(false);
    setDisableFour(true);
    setDisableTwo(false);
    setSelectedTab(1);
    setDisableOne(true);

    setStepOne(true);
    localStorage.removeItem("uploadDetails");
    setButtonDisabled(false);
    setShowList(false);
    setFailDataNew(false);
    setFailDataExists(false);
    setFailFileUrl('');
    setFailFileUrlExists('');
     setInsightData({
        totalUpload: 0,
        totalSuccess: 0,
        totalFail: 0,
        successPer: 0,
        failPer: 0,
        errorMsg: '',
        errorMsgExists: '',
        newTxtMsg: ''
    });
    setShowButton(true);
    
  }


  const startProcessNew = () => {
    setShowModalButton(false);
    setStepTwo(false);
    setStepThree(false);
    setStepFour(false);
    setDisableFour(true);
    setDisableTwo(true);
    setSelectedTab(0);
    setDisableOne(false);
    setHideButton(false);
    
    setStepOne(false);
    localStorage.removeItem("uploadDetails");
    setButtonDisabled(false);
    setShowList(false);
    setFailDataNew(false);
    setFailDataExists(false);
    setFailFileUrl('');
    setFailFileUrlExists('');
     setInsightData({
        totalUpload: 0,
        totalSuccess: 0,
        totalFail: 0,
        successPer: 0,
        failPer: 0,
        errorMsg: '',
        errorMsgExists: '',
        newTxtMsg: ''
    });
    setShowButton(false);
    
  }

  const updateUploadStatus = (actionType,uploadId,notes) => {
    updateFileUploadStatus(actionType,uploadId,notes,'upload')
      .then((response) => {
        if(response.data.code == 200){
          // setShowPopupButton(true);
          // setShowClose(false);
          startProcessNew();
        }else{
          setShowPopupButton(false);
          setShowClose(true);
          setModalBodyNew(response.data.data.message)
          setModalTitle('Message!');
          setShowModalButton(true);
        }
      }
    );
  }
 

  const clear_local = () => {
    localStorage.removeItem("uploadDetails");
    window.location.reload();
  }
 
 
  
  return (
    <>
       <div className='container-fluid mb-3 p-0'>
        <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md"> 
              <h4 className="mb-0 content-main-title">Data Migrate</h4>
            </div>
        </div>
       </div>
       <div className='content-UploadMemberDetails mb-3 p-0'>
        <div className="container-fluid">
          
          <div className="row align-items-center">
            <div className='col-md-12 px-0'>
            <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
            <TabList>
              <Tab style={{
                    opacity: disableOne ? 0.5 : 1,
                    cursor: disableOne ? 'not-allowed' : 'pointer',
                  }} disabled={disableOne}>
                {stepOne && <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/z3x4c/tjqoh9qujs.png' style={{width:'16px'}}></img> } Step 1
              </Tab>
              <Tab style={{
                    opacity: disableTwo ? 0.5 : 1,
                    cursor: disableTwo ? 'not-allowed' : 'pointer',
                  }} disabled={disableTwo}>
                {stepTwo && <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/z3x4c/tjqoh9qujs.png' style={{width:'16px'}}></img> } Step 2
              </Tab>
              <Tab style={{
                    opacity: disableThr ? 0.5 : 1,
                    cursor: disableThr ? 'not-allowed' : 'pointer',
                  }} disabled={disableThr}>
                {stepThree && <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/z3x4c/tjqoh9qujs.png' style={{width:'16px'}}></img> } Step 3
              </Tab>
              <Tab style={{
                    opacity: disableFour ? 0.5 : 1,
                    cursor: disableFour ? 'not-allowed' : 'pointer',
                  }} disabled={disableFour}>
                {stepFour && <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/z3x4c/tjqoh9qujs.png' style={{width:'16px'}}></img> } Step 4
              </Tab>
            </TabList>

            <TabPanel>
            <div className="container-fluid">
            <div className="row align-items-center mb-3">
             
              <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5lblt/c8zjv054j4.png" alt="Invited Members icon" style={{height:"240px"}}/>
                        </div>
              <div className="col-md">
                <h1 className='text-upload-main-title'>Data Migration</h1>
                <p className='text-upload-titlesub mb-4'>The Data Migration service makes it easy to transfer your members data to the GOQii HealthEngage System. Simply upload an Excel sheet with the member data. Download the template below, add the member information, and upload the file.</p>
                <p className='text-upload-titlesub mb-4'>Click <a href="" data-bs-toggle="modal" data-bs-target="#bulkDataMigrationModal">here</a> to know more about upload format.</p>
                <p className='text-upload-titlesub mb-4'>Click <a href="" data-bs-toggle="modal" data-bs-target="#careProgramModal">here</a> to know more Care Programs: Programs and Conditions</p>
                <p className='text-upload-titlesub mb-4'><a href={fileUrl}>Download</a>  the Data Migration template file.</p>
                {!showButton && <button type="button"  id="startProcess" onClick={() => startProcess()} className="btn btn-success uplaod-file-btn">Start Migration</button> }
              </div>
             
            </div>
          </div>
            </TabPanel>
            <TabPanel>
            {showButton && 
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      <div className='col-md-12'>
                        <h1 className='text-upload-main-title mb-3'>Upload Member Migration data file</h1>
                      </div>
                      <div className='d-flex'>
                        <div className="col-4">
                          <input type="file" id="xlaxFile_upload" className="form-control input-field-ui" disabled={buttonDisabled} accept=".xls, .xlsx, application/excel,application/vnd.msexcel, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required="required" onChange={handleChange}/>
                        </div>
                        
                        {hideButton && <div className="col-6 ps-5">
                          <button type="button" id="xlsxSubmit" onClick={uploadPreFile} disabled={buttonDisabled} className="btn btn-success uplaod-file-btn">Upload File</button>
                        </div> }
                      </div>
                      
                      <div className='col-4 mt-4 mb-1'>
                        <h4 className='text-allowed-file'>Allowed file type: xlsx 
                        <br/>
                        Allowed File Size: 2 MB(For UAT)</h4>
                      </div>
                                            
                    </div>
                  </div>
              }
              {buttonDisabled && 
           
              <div className="container-fluid">
                <div className="row align-items-center mb-3">
                  <div className="col-12">
                    <div className="col-12 my-4">
                        <p className='text-upload-titlesub'><b>{textMsg}</b></p>
                      </div>
                  </div>
                  
                  <div className="col-6">
                    <button type="button"  id="migrateData" onClick={() => uploadFile_Html(tempUpload)} disabled={showList} className="btn btn-success program-save-btn me-3">Validate Data</button>
                    <button type="button"  id="cancelData" onClick={clear_local} disabled={showList} className="btn btn-success clear-btn">Cancel</button>
                    
                  </div>
                </div>
              </div>
          }
            </TabPanel>
            <TabPanel>
            {showList &&  
                  <div className="container-fluid">
                    <div className="row mt-3">
                      <div className="col-6">
                        {showLoaderNew ? 
                        <>
                          {buttonDisabled && <h4 className='text-upload-title'>Validation is in progress, please wait….</h4> }
                          <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/48lld/8hx4bl7sy4.gif' style={{width:'100px'}}></img>
                        </>
                        : 
                        <h4 className='text-upload-title'>Data migration Completed</h4>}
                        <div className="progress-stacked" style={{"height": "6px"}}>
                            <div className="progress" role="progressbar" style={{ width: `${insightData.successPer}%` }}> 
                                <div className="progress-bar" style={{"backgroundColor": "#64C920"}}></div>
                            </div>
                            <div className="progress" role="progressbar" style={{ width: `${insightData.failPer}%` }}> 
                                <div className="progress-bar" style={{"backgroundColor": "#FF0000"}}></div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                  <h4 className='text-allowed-file'>Successful: {insightData.totalSuccess}/{insightData.totalUpload} ({insightData.successPer}%)</h4>
                            </div>
                            <div className='col-6 text-end'>
                                  <h4 className='text-allowed-file'>Failed: {insightData.totalFail} ({insightData.failPer}%)</h4>
                            </div>
                        </div>
                        <div className='row mt-2'>
                              <div className='col-12'>
                                <p className='text-upload-titlesub'><b>{insightData.newTxtMsg}</b></p>
                              </div>
                        </div>
                      </div>
                    </div>
                  </div> 
              }
            </TabPanel>
            <TabPanel className="px-0">
            {showList &&  
              <>
              {/* <textarea type="text" placeholder="Include key details about the data (e.g., program, condition) and any related hypotheses or assumptions for future reference." rows="4" className='form-control control-input-ui-addmember' name="topDoctorsVisits" value={notes} style={{resize: "none"}} onChange={setFileNotes}></textarea> */}
              {uniqueUploadId && <RCMFileInsight uploadId={uniqueUploadId} startProcessNew={startProcessNew} updateUploadStatus={updateUploadStatus}/> }
              
              </>
                  // <div className="container-fluid">
                  //   <div className="row mt-3">
                  //     <div className="col-6">
                  //       {showLoaderNew ? 
                  //       <>
                  //         {buttonDisabled && <h4 className='text-upload-title'>Data migration is in progress, please wait….</h4> }
                  //         <img src='https://storage.googleapis.com/ksabupatest/2024/09/24/48lld/8hx4bl7sy4.gif' style={{width:'100px'}}></img>
                  //       </>
                  //       : 
                  //       <h4 className='text-upload-title'>Data migration Completed</h4>}
                  //       <div className="progress-stacked" style={{"height": "6px"}}>
                  //           <div className="progress" role="progressbar" style={{ width: `${insightData.successPer}%` }}> 
                  //               <div className="progress-bar" style={{"backgroundColor": "#64C920"}}></div>
                  //           </div>
                  //           <div className="progress" role="progressbar" style={{ width: `${insightData.failPer}%` }}> 
                  //               <div className="progress-bar" style={{"backgroundColor": "#FF0000"}}></div>
                  //           </div>
                  //       </div>
                  //       <div className='row'>
                  //           <div className='col-6'>
                  //                 <h4 className='text-allowed-file'>Successful: {insightData.totalSuccess}/{insightData.totalUpload} ({insightData.successPer}%)</h4>
                  //           </div>
                  //           <div className='col-6 text-end'>
                  //                 <h4 className='text-allowed-file'>Failed: {insightData.totalFail} ({insightData.failPer}%)</h4>
                  //           </div>
                  //       </div>
                  //       <div className='row mt-2 align-items-center'>
                  //             <div className='col-md'>
                  //               <p className='text-upload-titlesub mb-0'><b>{insightData.newTxtMsg}</b></p>
                  //             </div>
                  //             <div className='col-md-auto'>
                  //             {stepFour && <>
                  //               <textarea type="text" placeholder="Add Notes" rows="4" className='form-control control-input-ui-addmember' name="topDoctorsVisits" value={notes} style={{resize: "none"}} onChange={setFileNotes}></textarea>
                  //               {(errors) && <div className="text-danger">{errors}</div>}
                  //               <button type="button" id="startProcess" onClick={() => startProcessNew()} className="btn btn-success uplaod-file-btn me-1 mt-1">Start New Migration</button>
                  //               <button type="button" id="startProcess" onClick={() => updateUploadStatus('submitted',uniqueUploadId)} className="btn btn-success uplaod-file-btn me-1 mt-1">Submit for Approval</button>
                  //               <button type="button" id="startProcess" onClick={() => updateUploadStatus('cancelled',uniqueUploadId)} className="btn btn-success uplaod-file-btn mt-1">Cancel</button>
                  //             </> }
                  //             </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div> 
              }
            {/* {failDataNew.length > 0  && 
                
                  <div className="container-fluid">
                  <div className="row align-items-center mb-3">
                      <div className="col-10">
                        <h1 className="text-upload-main-title">{insightData.errorMsg}</h1>
                      </div>
                      <div className="col-2 text-end">
                        {failFileUrl && <a href={failFileUrl} target='_blank' className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Download file</a> }
                      </div>
                  </div>
                    <div className="row mt-3">
                    <div className="col-12">  
                        <div className="table-responsive tableDataMigrate">
                            {failDataNew && <UploadMemberDateTable failData={failDataNew} /> }
                        </div>
                        </div>
                    </div>
                  </div>
              } */}

              {/* {failDataExists.length > 0 && 
                
                  <div className="container-fluid">
                  <div className="row align-items-center mb-3">
                      <div className="col-10">
                        <h1 className="text-upload-main-title">{insightData.errorMsgExists}</h1>
                      </div>
                      <div className="col-2 text-end">
                        {failFileUrlExists && <a href={failFileUrlExists} target='_blank' className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Download file</a> }
                      </div>
                  </div>
                    <div className="row mt-3">
                    <div className="col-12">  
                        <div className="table-responsive tableDataMigrate">
                            {failDataExists && <UploadMemberDateTable failData={failDataExists} /> }
                        </div>
                        </div>
                    </div>
                  </div> 
              } */}
            </TabPanel>
          </Tabs>
            </div>
          </div>
        </div>
      </div>

      {loading ? <CustomLoader/> : ''}

      {showModal?<ModalBox handleClose={handleClose} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass} />:null}

      {showModalButton?<ModalBoxButton handleClose={handleCloseButton} showModaln={showModalButton} titlen={modalTitle} bodyn={modalBodyNew} type={modalClass}  button={showPopupButton ? startProcessNew : undefined}  hideButton={showClose}/>:null}

      <div className="modal fade" id="bulkDataMigrationModal" tabIndex="1" aria-labelledby="bulkDataMigrationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">Guidelines for Bulk Data Migration File Usage</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='bg-content mb-3'>
                                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljc16/8j62b3c2b8.png" alt="" className='img-fluid' />
                                      <h3 className='text-sub-title px-3'>File Structure Guidelines</h3>
                                      <ol className='text-content px-4 pb-3'>
                                        <li className='mb-2'>Preserve Column Structure: Do not delete any columns; each is vital for the migration process. Removing columns will lead to a migration failure.</li>
                                        <li>Maintain Column Order: The sequence of columns should remain unchanged. Reordering columns will result in errors.</li>
                                      </ol>
                                    </div>

                                    

                                    <div className='bg-content mb-3'>
                                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/gl78n/thg88a7jz4.png" alt="" className='img-fluid' />
                                      <h3 className='text-sub-title px-3'>Common Migration Errors and Remedies</h3>
                                      <ol className='text-content px-4 pb-3'>
                                        <li className='mb-2'>Missing Mandatory Data: Check for any missing entries in mandatory fields.</li>
                                        <li className='mb-2'>Duplicate 'Membership No': Eliminate any duplicate entries in the 'Membership No' field.</li>
                                        <li>Data Formatting Errors: Check that dates, email addresses, and numerical values are correctly formatted.</li>
                                        <li>Care Program and Chronic Conditions Mismatch: Ensure that the values for "Care Program" and "Chronic Conditions" exactly match the programs and conditions defined by the Medical Team. For reference, verify the names against the published Program and Condition names to avoid discrepancies.</li>
                                        
                                      </ol>
                                    </div>
                                </div>

                                <div className="col-md-6">

                                    <div className='bg-content mb-3'>
                                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qslun/t8suw4fhg4.png" alt="" className='img-fluid' />
                                      <h3 className='text-sub-title px-3'>Specific Field Instructions</h3>
                                      <ol className='text-content px-4 pb-3'>
                                        <li className='mb-2'>Gender: Only 'M' or 'F' are acceptable values for male and female, respectively.</li>
                                        <li className='mb-2'>Policy Status: Acceptable values are 'active contract' or 'lapsed contract'.</li>
                                        <li className='mb-2'>Height (cm): Record height in centimeters, with valid values ranging from 30 to 200 cm.</li> 
                                        <li>Weight (lbs): Record weight in pounds, with valid values ranging from 2 to 500 lbs.</li> 
                                      </ol>
                                    </div>
                                    <div className='bg-content mb-3'>
                                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/heghw/thrj5jsjgj.png" alt="" className='img-fluid' />
                                      <h3 className='text-sub-title px-3'>Data Validation Requirements</h3>
                                      <ol className='text-content px-4 pb-3'>
                                        <li className='mb-2'>Mandatory Fields: Confirm that all mandatory fields are populated. Missing information in these fields will fail the record.</li>
                                        <li className='mb-2'>Email Validation: Verify that each email address is correctly formatted.</li>
                                        <li className='mb-2'>Numeric Fields: Fields designated for numerical input, like 'Member Mobile No', 'Height (cm)', 'Weight (lbs)', and 'Total YTD', must not contain non-numeric characters.</li>
                                        <li>Date Fields: Enter dates in the format 'DD/MM/YYYY' for fields such as 'Date of Birth', 'Policy Start Date', and 'Policy End Date'.</li>
                                      </ol>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="careProgramModal" tabIndex="1" aria-labelledby="careProgramModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">Care Programs: Programs and Conditions</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive tableEmployeePolicy">
                                  <div className="container">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Program</th>
                                                <th>Condition</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {programData && programData.map((prg, index) => (
                                            <React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td rowSpan={prg.condition.length}>{prg.program}</td>
                                                    <td>{prg.condition[0]}</td>
                                                </tr>
                                                {prg.condition.slice(1).map((subDept, subIndex) => (
                                                    <tr key={`${index}-${subIndex}`}>
                                                        <td>{subDept}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                   </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </>
  );
}

export default ContentUploadMemberNew;