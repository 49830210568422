import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePickerPage from "../CalendarDatePickerPage";
import moment from "moment";
import { fetchUntouchRemove,removeUntouchMembers } from "../../../services/rcm/Service";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { CustomLoader } from "../../utils/CommonLibrary";

function RemoveUntouchMembers() {
  
  const today = moment();
  const sevenDaysAgo = moment().subtract(6, "days");

  const [startDate, setStartDate] = useState(sevenDaysAgo);  
  const [endDate, setEndDate] = useState(today);
  const [showAndOr, setShowAndOr] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [fileLink, setFileLink] = useState('');
  const [memberCount, setMemberCount] = useState('');
  const [removeId, setRemoveId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = ({ start, end,index }) => {
    setStartDate(start);
    setEndDate(end);

    const newQueries = [...queries];
    newQueries[index]['value'] = formatDate(start) + "," + formatDate(end);
    newQueries[index]['startDate'] = start;
    newQueries[index]['endDate'] = end;
    setQueries(newQueries);
  };

  const [queries, setQueries] = useState([
    { field: "m.createdTime", operator: "=", value: formatDate(sevenDaysAgo) + "," + formatDate(today), condition: null, startDate: sevenDaysAgo, endDate: today,type: "date" },
  ]);

  const fields = [
    { display: "Approved Date", value: "m.createdTime",type: "date" },
    { display: "Chronic Conditions", value: "mpcr.conditionId",type: "select" },
    { display: "Member Network", value: "me.memberNetwork",type: "text" },
    { display: "Policy End Date", value: "m.policyEndDate",type: "date" },
  ];


  const operators = [
    { display: "Equals", value: "=" },
    { display: "Greater than", value: ">" },
    { display: "Less than", value: "<" },
    { display: "Between", value: "between" },
  ];

  const programs = [
    { label: "Diabetes Mellitus", value: '1' },
    { label: "Obesity", value: '3' },
    { label: "test", value: '4' },
    { label: "Checking", value: '5' },
  ];



  const handleAddQuery = (condition) => {
    const newQueries = [...queries];
  
    newQueries[newQueries.length - 1].condition = condition;
    const selectedFields = newQueries.map(query => query.field);
    const availableField = fields.find(field => !selectedFields.includes(field.value))?.value || fields[0].value;
    const availableFieldObject = fields.find(field => field.value === availableField);

    const remainingFieldsCount = fields.filter(field => !selectedFields.includes(field.value)).length;
    if(remainingFieldsCount <=1 ){
      setShowAndOr(false);
    }

    let valueFinal = "";

    if (availableFieldObject?.type === 'date') {
      valueFinal = `${formatDate(sevenDaysAgo)},${formatDate(today)}`;
    }
  
    newQueries.push({ 
      field: availableField,
      operator: operators[0].value,
      value: valueFinal,
      condition: null,
      startDate: sevenDaysAgo,
      endDate: today,
      type: availableFieldObject?.type,
    });
  
    setQueries(newQueries);
  };
  
  


  const fetchRemoveData = async () =>{
    setLoading(true);
    setErrorMsg('');
    try{
      fetchUntouchRemove(JSON.stringify({ queries }))
        .then((response) => {   
            console.log('untouch response',response.data.code);
            if(response.data.code == 200){
              setFileLink(response.data.data.fileLink);
              setMemberCount(response.data.data.memberCount);
              setRemoveId(response.data.data.removeId);
              setShowRemove(true);
            }else{
              setErrorMsg(response.data.data.msg);
            }
            setLoading(false);
        });
    }catch(error) {
        console.error('Error=', error);
    }
}

const RemoveMembers = async () =>{
  setLoading(true);
  try{
    removeUntouchMembers(removeId)
      .then((response) => {   
          console.log('RemoveMembers',response.data.code);
          if(response.data.code == 200){
            setSuccessMsg(response.data.data.msg);
            setShowRemove(false);
            setTimeout(() => {
              setSuccessMsg(false);
            }, 3000);
          }else{
            setErrorMsg(response.data.data.msg);
          }
          setLoading(false);
      });
  }catch(error) {
      console.error('Error=', error);
  }
}

  const handleRemoveQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    if (newQueries.length > 0 && index > 0) {
      newQueries[index - 1].condition = null;
    }
    setQueries(newQueries);
    setShowAndOr(true);
  };

  const handleQueryChange = (index, key, value, fieldType) => {
    const newQueries = [...queries];
    if (key === "field" && (fieldType === "select" || fieldType === "text")) {
      newQueries[index].value = "";
    }

    if (key === "field" && fieldType === "date") {
      newQueries[index].value = `${formatDate(sevenDaysAgo)},${formatDate(today)}`;
    }

  
    newQueries[index][key] = value;
    newQueries[index]['type'] = fieldType;
    setQueries(newQueries);
  };

  const handleMultiSelectChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value).join(",") : "";
    handleQueryChange(index, "value", selectedValues,"select");
  };

  const getAvailableFields = (index) => {
    const selectedFields = queries.slice(0, index).map(query => query.field); // Only check previous fields
    return fields.filter(field => !selectedFields.includes(field.value));
  };
  

  const renderInput = (query, index) => {
    if (query.type === "date") {
      return (
        <CalendarDatePickerPage
          startDate={query.startDate}
          endDate={query.endDate}
          onDateChange={handleDateChange} 
          index={index} 
        />
      );
    } else if (query.type === "select") {
      
      const selectedConditions = programs.filter(program => query.value.includes(program.value));
      
      return (
        <MultiSelect
          options={programs}
          value={selectedConditions.length > 0 ? selectedConditions : []}
          onChange={(selectedOptions) => handleMultiSelectChange(index, selectedOptions)}
          labelledBy="Select"
          hasSelectAll={true}
          className="form-select select-input-ui select-input-ui-select"
          disableSearch={false}
          overrideStrings={{
            selectAll: 'Select All',
            allItemsAreSelected: 'All items are selected',
            selectSomeItems: 'Select items'
          }}
        />
      );
    } else {
      return (
        <input
          type="text"
          value={query.value}
          className="form-control"
          onChange={(e) => handleQueryChange(index, "value", e.target.value,"text")}
        />
      );
    }
  };
  

  return (
    <>
      <section className="ReportsPrograms">
        <div className="container-fluid px-0 mb-3 ">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md ">
              <h1 className="main-title">Remove Untouched Members</h1>
            </div>
          </div>
          <div className="reports-programs mb-3">
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3ysv/7sg4f87em4.png"
                  alt="Invited Members icon"
                  style={{ height: "140px" }}
                />
              </div>
              <div className="col-md">
                <p className="mb-0">
                  The Reports allows users to generate and download various reports of the data sets
                  migrated into the system. This feature provides valuable insights and ensures you
                  have comprehensive records of all migrated data.
                </p>
              </div>
            </div>
          </div>

          <div className="reports-programs mb-3">
            <div className="row">
              <div className="col-md-12">
                <div>
                  {errorMsg && <span className="text-danger">{errorMsg}</span>}
                  {successMsg && <span className="text-success">{successMsg}</span>}
                  {queries.map((query, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <select
                        value={query.field}
                        className="form-select"
                        onChange={(e) => {
                          const selectedField = fields.find(field => field.value === e.target.value);
                          handleQueryChange(index, "field", selectedField.value, selectedField.type);
                        }}
                      >
                        {getAvailableFields(index).map((field) => (
                          <option key={field.value} value={field.value}>
                            {field.display}
                          </option>
                        ))}
                      </select>

                      <select
                        value={query.operator}
                        className="form-select"
                        onChange={(e) => {
                          const selectedField = fields.find(field => field.value === query.field);
                          handleQueryChange(index, "operator", e.target.value, selectedField?.type);
                        }}
                      >
                        {operators.map((operator,i) => (
                          <option key={i} value={operator.value}>
                            {operator.display}
                          </option>
                        ))}
                      </select>

                      {renderInput(query, index)}

                      {queries.length > 1 && (
                        <button className="btn btn-danger m-2" onClick={() => handleRemoveQuery(index)}><i className="bi bi-trash"></i></button>
                      )}

                      {index < queries.length - 1 && query.condition && (
                        <div>
                          <strong>{query.condition}</strong>
                        </div>
                      )}

                      {index === queries.length - 1 && showAndOr && (
                        <div style={{ marginTop: "10px" }}>
                          <button className="btn btn-success me-2" onClick={() => handleAddQuery("AND")}>AND</button>
                          <button className="btn btn-success" onClick={() => handleAddQuery("OR")}>OR</button>
                        </div>
                      )}
                    </div>
                  ))}

                  <button className="btn btn-success uplaod-file-btn" onClick={() => fetchRemoveData()}>Filter</button>


                  {showRemove && memberCount && <span>Total {memberCount} will be remove</span>}
                  {showRemove && fileLink && <Link to={fileLink}>Download File</Link>}

                  <br/><br/>

                  {showRemove && removeId && <button className="btn btn-success uplaod-file-btn" onClick={() => RemoveMembers()}>Remove Members</button> }
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <CustomLoader /> : ''}
      </section>
    </>
  );
}

export default RemoveUntouchMembers;
